.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background-color: rgba(35, 35, 35, 0.9);
}

.white_bg {
  background-color: #fff !important;
}

.modal {
  position: fixed;
  top: 11vh;
  left: 5%;
  width: 90%;
  background-color: #fefefe;
  padding: 1.6rem 0;
  padding-top: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 1002;
  animation: slide-down 300ms ease-out forwards;
}

.modal__header {
  margin-bottom: 2rem;
}

.search_modal {
  position: fixed;
  width: 100%;
  background-color: white;
  padding: 1.1rem;
  padding-top: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 1002;
  animation: slide-down 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .modal {
    width: 25rem;
    left: calc(50% - 12.5rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll {
  padding: 0 1.6rem;
  overflow: auto;
  max-height: 500px;
}

/* Customize the scrollbar */
.scroll::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #cbcbcb; /* Set the background color of the track */
  border-radius: 50px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #888; /* Set the color of the scrollbar handle */
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set the color of the scrollbar handle on hover */
}
